import { Provider, useStore } from 'react-redux'
import { wrapper } from '@/redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { useCallback, useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { AppWrapper } from '../context/AppContext'
import { useRouter } from 'next/router'
import PageLoader from '@/ui/components/shared/PageLoader'

import '../styles/globals.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-placeholder/lib/reactPlaceholder.css'
import './../styles/cookieconsent.css'
import ServerLoader from '@/ui/components/shared/ServerLoader'
import Head from 'next/head'
import Script from 'next/script'
import { hotjar } from 'react-hotjar'
import { setCookies } from 'cookies-next'

const MyApp = ({ Component, pageProps }) => {
	const store = useStore()

	const persistor = store.__persistor
	const router = useRouter()
	const [lift, setLift] = useState(false)

	const rehydrateStore = useCallback(() => {
		// persistor.purge()
		// persistor.dispatch({ type: 'PURGE' })
		if (store.getState().user.token) setCookies('token', store.getState().user.token, { secure: false })

		persistor.dispatch({ type: 'PURGE' })
	}, [persistor])

	useEffect(() => {
		rehydrateStore()
	}, [rehydrateStore])

	const [loading, setLoading] = useState(false)

	useEffect(() => {
		const handleStart = url => url !== router.asPath && setLoading(true)
		const handleComplete = () => {
			setLoading(false)
		}

		router.events.on('routeChangeStart', handleStart)
		router.events.on('routeChangeComplete', handleComplete)
		router.events.on('routeChangeError', handleComplete)

		return () => {
			router.events.off('routeChangeStart', handleStart)
			router.events.off('routeChangeComplete', handleComplete)
			router.events.off('routeChangeError', handleComplete)
		}
	})

	useEffect(() => {
		hotjar.initialize(3148545, 6)

		const bbVersion = process.env.NEXT_PUBLIC_BITBUCKET_COMMIT.slice(0, 3)
		const whitelist = ['tokenExpiration', 'resetTokenExp']

		let regexPattern = new RegExp(`^persist:.*${bbVersion}$`)
		Object.keys(localStorage).map(item => {
			if (!whitelist.includes(item) && !regexPattern.test(item)) {
				localStorage.removeItem(item)
			}
		})
	}, [])

	const _renderHead = () => {
		return (
			<Head>
				<title>{process.env.NEXT_PUBLIC_APP_NAME}</title>
				<meta content="width=device-width,minimum-scale=1.0" name="viewport" />
				<meta name={'description'} content={'Od pondělí do neděle TURBO PIZZA chutná skvěle.'} />
				<meta name="author" content="DEEP VISION s.r.o. <info@deepvision.cz>" />
				<meta name="robots" content="all, follow" />
				<link rel="apple-touch-icon" sizes="57x57" href="/favicon/apple-icon-57x57.png" />
				<link rel="apple-touch-icon" sizes="60x60" href="/favicon/apple-icon-60x60.png" />
				<link rel="apple-touch-icon" sizes="72x72" href="/favicon/apple-icon-72x72.png" />
				<link rel="apple-touch-icon" sizes="76x76" href="/favicon/apple-icon-76x76.png" />
				<link rel="apple-touch-icon" sizes="114x114" href="/favicon/apple-icon-114x114.png" />
				<link rel="apple-touch-icon" sizes="120x120" href="/favicon/apple-icon-120x120.png" />
				<link rel="apple-touch-icon" sizes="144x144" href="/favicon/apple-icon-144x144.png" />
				<link rel="apple-touch-icon" sizes="152x152" href="/favicon/apple-icon-152x152.png" />
				<link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-icon-180x180.png" />
				<link rel="icon" type="image/png" sizes="192x192" href="/favicon/android-icon-192x192.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="96x96" href="/favicon/favicon-96x96.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
				<link rel="shortcut icon" href="/favicon/favicon.ico" />
				<Script src="https://api.mapy.cz/loader.js" />
			</Head>
		)
	}

	const _renderGtag = () => {
		return (
			<>
				<Script src="https://www.googletagmanager.com/gtag/js?id=G-ZF8QQ1GHSZ" strategy={'afterInteractive'} />
				<Script strategy={'afterInteractive'}>
					{`
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){window.dataLayer.push(arguments);}
                      gtag('js', new Date());
            
                      gtag('config', 'G-ZF8QQ1GHSZ', {
                          page_path: window.location.pathname,
                      });
                    `}
				</Script>
			</>
		)
	}

	const onBeforeLift = () => {
		// Take an action before the gate lifts
		setTimeout(() => {
			setLift(true)
		}, 3000)
	}

	return process.browser ? (
		<PersistGate persistor={persistor} loading={<div>Loading</div>}>
			{_renderHead()}
			{_renderGtag()}
			<span className={'hidden'}>Od pondělí do neděle TURBO PIZZA chutná skvěle.</span>
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={true}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
			/>
			<AppWrapper>{loading ? <PageLoader /> : <Component {...pageProps} />}</AppWrapper>
		</PersistGate>
	) : (
		<Provider store={store} loading={<div>Loading</div>}>
			{_renderHead()}
			{_renderGtag()}
			<span className={'hidden'}>Od pondělí do neděle TURBO PIZZA chutná skvěle.</span>
			<PersistGate
				persistor={persistor}
				onBeforeLift={onBeforeLift}
				loading={lift ? <ServerLoader /> : <PageLoader />}
			>
				<Component {...pageProps} />
			</PersistGate>
		</Provider>
	)
}

export default wrapper.withRedux(MyApp)
